<template>
    <div class="calendarBox">
        <van-cell-group >
            <van-field :label='label'  rows="1" type="textarea" autosize readonly @click="OpenCalendarDig" :placeholder='placeholder'  :rules='rules' clickable is-link required :value="isValue " />
        </van-cell-group>
        <van-popup ref="refPopup" @close='closePopup' get-container="body"  v-model="show" :closeable='!IsTimeFlag' position="bottom" :style="{ height: '70%' }" :safe-area-inset-bottom='true'>
            <div class="PopubBox">
                <van-calendar @select='CalendarSelect' @unselect='unselectClick' type="multiple"  v-if="isCalendarShow"   confirm-disabled-text='请选择实验室预约时间' :poppable="false" :color="'#1989fa'" :default-date='isValuedefaultDate' title="实验室使用时间" :min-date="minDate" :max-date="maxDate"  @confirm="onConfirmCalendar" :formatter="formatters"/>
                <div class="DataDeteils" v-if="IsTimeFlag">
                    <h4 class="PupuTiTLE van-hairline--bottom">{{IsTabActiveFlag}}预约时间段</h4>
                    <div class="TimeBox" :a='IsTabActiveFlag'>
                        <div class="van-hairline--bottom RowBox" v-for="(item, index) in TimeSlotArry" :key="index">
                            <h5 class="H5Title van-hairline--bottom">{{item.name}}</h5>
                            <div class="TimeEventBox clearfix">
                                
                                <span  :class="{spanActive:TestValue[IsTabActiveFlag]?(TestValue[IsTabActiveFlag].indexOf(ite.Code_Value) > -1?true:false):false, 'spanActive-disabled':disabledArry[IsTabActiveFlag]?(disabledArry[IsTabActiveFlag].indexOf(ite.Code_Value) > -1?true:false):false }" class="van-hairline--surround " @click="SpanClickActive(ite ,disabledArry[IsTabActiveFlag]?(disabledArry[IsTabActiveFlag].indexOf(ite.Code_Value) > -1?true:false):false)"  v-for="(ite, index) in item.value" :key="index">{{ite.Code_Name}} </span>
                            </div>
                        </div>
                    </div>
                    <div class="BtnBox">
                        <div class="BtnEvent"> 
                            <van-button  size="small" type="default" @click="cancelSelectd(IsTabActiveFlag)">关闭预约</van-button>
                        </div>
                        <div class="BtnEvent">
                            <van-button  size="small" type="primary" @click="OkSelectd">确认选中</van-button>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import {CellGroup ,Field,Calendar,Popup  ,Button ,Toast } from 'vant';
import {Api_YyMAALaboratoryAppointment_GetYYTime} from '@/api/laboratory'
import {Datetime} from '@/filters/index'
import { Api_GetCodeByIntTypes } from '@/api/utils'
 
 

export default {
    name:'labCalendar',
    components:{
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Calendar.name]: Calendar,
        [Popup.name]: Popup,
        [Button.name]: Button,
        [Toast.name]: Toast,
    },
    model:{
        prop: 'TestValue',
        event: 'input'
    },

    props:{
        TestValue:{
            type:String | Object,
            default:()=>{
                return {
                 
                }
            }
        },
        label:{
           type:String,
           default:''
        },
        placeholder:{
            type:String,
            default:'请输入'
        },
        rules:{
            type:Array,
            default:()=>{
                return []
            }
        },
        laboratoryId:{
            type:String | Number,
            default:''
        },
        laboratoryLAID:{
            type:String | Number,
            default:0
        },
    },
    computed:{
        // 使用时间显示
        isValue:{
            get:function(){
                var StringStr = ''
                for (let key in this.TestValue) {

                        StringStr += key+`、`
                }
                return StringStr.substring(0,StringStr.length - 1)
            },
            set:function(newVal){
                this.$emit('input',newVal)
            },
        },
        isValuedefaultDate:{
            get:function(){
                let Arry = []
                for (let key in this.TestValue) {
                  
                    Arry.push(new Date(key))
                }
                if(Arry.length > 0){
                    return Arry
                }else {
                    return null
                }
            },
            set:function(newVal){
                console.log(newVal)
            },
        },
    },
    data() {
        return {
            IsTimeFlag:false,
            IsTabActiveFlag:"",
            isCalendarShow:true,
            show:false,
            minDate: new Date(),
            maxDate: new Date(''+new Date().getFullYear()+'/'+(new Date().getMonth()+2)+'/'+new Date().getDate()+''),
            TimeSlotArry:[],
            disabledArry:{}
        }
    },
    created(){
        this.GetCode()
    },
    methods:{
        // 获取时间段
        GetCode(){
            Api_GetCodeByIntTypes({
                type:'TimeSlotMorning-TimeSlotAfternoon-TimeSlotNight'
            }).then(res=>{
                this.TimeSlotArry = [
                    {
                        name:'上午',
                        value:res.TimeSlotMorning                  
                    },
                    {
                        name:'下午',
                        value:res.TimeSlotAfternoon                  
                    },
                    {
                        name:'晚上',
                        value:res.TimeSlotNight                  
                    },
                ]
               
            })
        },
        OkSelectd(){
            if( this.TestValue[this.IsTabActiveFlag].length > 0){
                var Flag = {}
                for (const key in this.TestValue) {
                    if(this.TestValue[key].length > 0){
                        Flag[key] = this.TestValue[key]
                    }       
                }
                this.$emit('input',Flag)
                this.IsTimeFlag = false   
            }else {
                this.$toast('请选择预约时间段')
            }
        },
        // 实验室反查时间段
        // lookupTime(value){
        //     var FlagConcatArry = []

        //     var StringSrt = []
        //     this.TimeSlotArry.forEach(ele=>{
        //         FlagConcatArry = FlagConcatArry.concat(ele.Code_Value)
        //     })

        //     FlagConcatArry.forEach(ele=>{
        //         if(value.indexOf(ele.Code_Value) > -1) {
        //             StringSrt.push(ele.Code_Name)
        //         }
        //     })
        //     return StringSrt.join(',') 
        // },
        //取消时间段选中
        cancelSelectd(value){
            // this.TestValue[value] = []
            var Flag = {}
            for (const key in this.TestValue) {
                if(this.TestValue[key].length > 0){
                    Flag[key] = this.TestValue[key]
                }       
            }
            this.$emit('input',Flag)
            this.IsTimeFlag = false
        },
        // 取消选中日期时触发
        unselectClick(value){
            if(this.TestValue[this.$options.filters['Datetime'](value,'yyyy-mm-dd')].length > 0) {
                this.isValuedefaultDate.push(value)
                this.IsTabActiveFlag = this.$options.filters['Datetime'](value,'yyyy-mm-dd')
                this.IsTimeFlag = true
            }else {

                var Flag = {}
                for (const key in this.TestValue) {
                    if(this.TestValue[key].length > 0){
                        Flag[key] = this.TestValue[key]
                    }
                        
                }
                this.$emit('input',Flag)
            }
        },
        // 点击并选中任意日期时触发
        CalendarSelect(value){          
            var Flag = {}
            for (const key in this.TestValue) {
                if(this.TestValue[key].length > 0){
                    Flag[key] = this.TestValue[key]
                }
            }
            Flag[this.$options.filters['Datetime'](value[value.length-1],'yyyy-mm-dd')] = []
            this.$emit('input',Flag)
            this.IsTabActiveFlag = this.$options.filters['Datetime'](value[value.length-1],'yyyy-mm-dd')
            this.$nextTick(()=>{

                this.IsTimeFlag = true
            })
        },
        SpanClickActive(value,isStuts){
            
            if(isStuts) return
            if(this.TestValue[this.IsTabActiveFlag]) {
                if(this.TestValue[this.IsTabActiveFlag].indexOf(value.Code_Value) >-1 ) {
                    this.TestValue[this.IsTabActiveFlag] = this.TestValue[this.IsTabActiveFlag].filter(element=>{
                        return element !=value.Code_Value
                    })
                }else {
                    this.TestValue[this.IsTabActiveFlag].push(value.Code_Value)
                }
            }else {
                this.TestValue[this.IsTabActiveFlag] = []
                this.TestValue[this.IsTabActiveFlag].push(value.Code_Value)
                
            }

            var Flag = {}
            for (const key in this.TestValue) {
                if(this.TestValue[key].length > 0){
                    Flag[key] = this.TestValue[key]
                }       
            }
            this.$emit('input',Flag)
           
        },
        
        // 关闭弹窗
        closePopup(){
            var Flag = {}
            for (const key in this.TestValue) {
                if(this.TestValue[key].length > 0){
                    Flag[key] = this.TestValue[key]
                }       
            }
            this.$emit('input',Flag)

            this.IsTimeFlag = false
            this.isCalendarShow = false;
        },
        // //打开日历弹窗
        OpenCalendarDig(){
            if(this.laboratoryId==='' || this.laboratoryId=== null || this.laboratoryId=== 'null' || this.laboratoryId=== undefined){
                this.$toast('请选择实验室名称')
            }else {
                
                Api_YyMAALaboratoryAppointment_GetYYTime({
                    IID:this.laboratoryId,
                    LAID:this.laboratoryLAID
                }).then(res=>{
                    
                    
                    this.disabledArry = res
                    if(this.$refs.refPopup){
                        this.isCalendarShow = true;
                        this.$nextTick(()=>{
                            this.show = true
                        })
                    }else {
                        this.show = true
                    } 
                })
            }
        },
        formatters(day){
            // console.log(day)
            if(day.type != "disabled"){
                if(day.type === "start" || day.type === "multiple-middle" || day.type === "end" || day.type === "multiple-selected") {
                    day.bottomInfo = '当前预约'
                }
                var year = day.date.getFullYear()
                var month = day.date.getMonth() + 1 + ''
                var days = day.date.getDate() + ''
              
                if(this.disabledArry.hasOwnProperty(this.$options.filters['Datetime'](year +'-' +month + '-' + days,'yyyy-mm-dd')) && this.disabledArry[this.$options.filters['Datetime'](year +'-' +month + '-' + days,'yyyy-mm-dd')].length < 1) {
                     day.bottomInfo = '已预约'
                     day.type = "disabled"

                }
            }
            return day
        },
        onConfirmCalendar(time){
            this.$emit('input',this.TestValue)
            this.show = false
        },
    }
}
</script>
<style lang="scss" scoped>
    .PopubBox {
        height: 100%;
        position: relative;
        .PupuTiTLE {
            text-align: center;
            font-size: 15px;
            color: #999999;
            height: 44px;
            line-height: 44px;
        }
        .DataDeteils {
            box-sizing: border-box;
            background: #fff;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            // padding-top: 8px;
            .TitleBox {
                width: 100%;
                padding: 8px 0px;
                font-size: 14px;
                text-align: center;
                color: #646566;
                h4 {
                    padding-bottom: 5px;
                }
            }
            .active {
                background: #ff9254;
                color: #fff;
               
            }
            .active::after {
                border-color: #ff9254;
            }
            
            .H5Title {
                font-size: 14px;
                color: #333;
                padding: 8px 12px;
                margin-bottom: 8px;
                color: #ff9254;
            }
            .RowBox {
                padding-bottom: 5px;
            }
            .TimeEventBox {
                color: #646566;
                padding: 0px 12px;
                span {
                    font-weight: bold;
                    font-size: 13px;
                    margin-right: 12px;
                    margin-bottom: 10px;
                    padding: 4px 6px;
                    // border-radius: 5px;
                    float: left;
                    -webkit-user-select:none;
                    -moz-user-select:none;
                    -ms-user-select:none;
                    user-select:none;
                }
            }
            .TimeBox {
                height: calc(100% - 98px) ;
                overflow-y: auto;
            }
            .BtnBox {
                display: flex;
                flex-direction: row;
                padding: 8px 0px;
                .BtnEvent {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    height: 38px;
                    text-align: center;
                    flex: 1;
                    .van-button--small {
                        width: 90%;
                    }
                }
            }
            .spanActive {
                background: #ff9254;
                color: #fff;
            }
            .spanActive-disabled {
                color: #c8c9cc;
                cursor: not-allowed;
            }
            .spanActive::after {
                border-color: #ff9254;
            }
        }
    }
    /dee/ .van-calendar__body {
        
    }
</style>