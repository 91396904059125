import request from '@/utils/request';
// 实验室预约列表
export function Api_YyMAALaboratoryAppointment_GetPage(params){
    return request('/YyMAALaboratoryAppointment/GetPage',{
        method:'post',
        data: params
    })
}
// 实验室预约列表
export function Api_YyMAALaboratoryAppointment_GetInfo(params){
    return request('/YyMAALaboratoryAppointment/GetInfo',{
        method:'post',
        data: params
    })
}
// 实验室预约 新增修改
export function Api_YyMAALaboratoryAppointment_AddEditInfo(params){
    return request('/YyMAALaboratoryAppointment/AddEditInfo',{
        method:'post',
        data: params
    })
}
