import request from '@/utils/request';

export function Api_SysCode_UploadImg(params){
    return request('/SysCode/UploadImg',{
        method:'post',
        data: params
    })
}
//获取 Number 类型 codeName
export function Api_GetCodeByIntTypes(params){
    return request('/SysCode/GetCodeByIntTypes',{
        method:'get',
        params: params
    })
}