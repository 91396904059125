import request from '@/utils/request';
// 实验室概览列表
export function Api_YyInformationController_GetPage(params){
    return request('/YyInformationController/GetPage',{
        method:'post',
        data: params
    })
}
// 实验室概览详情
export function Api_YyInformationController_GetInfo(params){
    return request('/YyInformationController/GetInfo',{
        method:'post',
        data: params
    })
}
// 获取实验室下拉列表
export function Api_YyMAALaboratoryAppointment_ReservationLaboratoryList(params){
    return request('/YyMAALaboratoryAppointment/ReservationLaboratoryList',{
        method:'get',
        params: params
    })
}
// 获取 审核通过前置审批单下拉
export function Api_YyMAALaboratoryAppointment_DangerousApplicationList(params){
    return request('/YyMAALaboratoryAppointment/DangerousApplicationList',{
        method:'get',
        params: params
    })
}
// 预约提交
export function Api_YyMAALaboratoryAppointment_AddEditInfo(params){
    return request('/YyMAALaboratoryAppointment/AddEditInfo',{
        method:'post',
        data: params
    })
}
// 获取实验室不可预约时间
export function Api_YyMAALaboratoryAppointment_GetYYTime(params){
    return request('/YyMAALaboratoryAppointment/GetYYTime',{
        method:'get',
        params: params
    })
}